<template>
	<div class="apply">
		<div class="page-section" :class="$responsive">
			<h2 class="title" v-markdown:title>
				Aplica en zione.*sport*
			</h2>
			<div v-if="!sent">
				<apply-form @sent="sentDone"></apply-form>
			</div>
			<div v-else>
				<success-message :contactName="contactName">
					hemos recibido tus datos.
				</success-message>
			</div>
		</div>
	</div>
</template>

<script>
	import applyForm from "@/components/ApplyForm.vue";
	import successMessage from "@/components/SuccessMessage.vue";
	
	export default {
		name: "Apply",
		components: {
			applyForm,
			successMessage
		},
		data() {
			return {
				sent: false,
				contactName: null
			}
		},
		methods: {
			sentDone(contactName) {
				this.contactName = contactName;
				this.sent = true;
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			}
		}
	}
</script>

<style>

.page-section.mobile {
	padding: 1rem;
}

.title {
	margin: 4rem auto;
}
</style>