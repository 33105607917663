<template>
	<div class="apply-form-component" :class="$responsive">
		<p class="subtitle">
			Cuéntanos sobre ti y te notificaremos sobre puestos relevantes y oportunidades que puedan interesarte.
		</p>
		<form class="apply-form" method="post" action="#" @submit.prevent="submitForm">
			<div v-for="(field, key, index) in contact" :key="key">
				<form-field :index="index" :field="field" v-model.trim="field.value" />	
			</div>
			<baseInputFile type="application/pdf" size="5" @file="attachFile">
				Sube tu currículum o portafolio
				<template v-slot:helptext v-if="resume.error">
					<span class="text--highlighted">Requerido *</span>
				</template>
			</baseInputFile>
			<base-button class="apply-form__submit">
				Enviar Información
			</base-button>
			<re-captcha ref="recaptcha" @verify="doSubmit"></re-captcha>
		</form>
	</div>
	<modal-processing v-if="isSending">
		Enviando tu información..
	</modal-processing>
</template>

<script>
const axios = require('axios');
import reCaptcha from "@/components/ReCaptchaV2.vue";
import modalProcessing from "@/components/ModalProcessing.vue";
import baseButton from "@/components/BaseButton.vue";
import baseInputFile from "@/components/BaseInputFile";
import formField from "@/components/FormField";
import useForm from "@/forms.js";

export default {
	name: "ApplyForm",
	components: {
		reCaptcha,
		modalProcessing,
		baseButton,
		baseInputFile,
		formField
	},
	emits: ['sent'], // new on Vue3
	setup() {
		const { checkForm } = useForm();
		return { checkForm };
	},
	data() {
		return {
			contact: {
				name: {
					value: "",
					type: "text",
					label: "Nombre",
					required: true,
					errorMessage: []
				},
				email: {
					value: "",
					type: "email",
					label: "Correo electrónico",
					required: true,
					errorMessage: []
				},
				phone: {
					value: "",
					type: "text",
					label: "Teléfono",
					required: true,
					errorMessage: []
				},
				city: {
					value: "",
					type: "text",
					label: "Ciudad / País",
					required: true,
					errorMessage: []
				},
				areaOfInterest: {
					value: "",
					type: "select",
					label: "Área de interés",
					values: [null, 'Clientes & Ventas', 'Tecnología', "Diseño", "Operaciones"],
					required: true,
					errorMessage: [],
					helpText: "Área o función donde te interesa aplicar"
				},
				student: {
					value: "No",
					type: "select",
					label: "¿Estudias actualmente?",
					values: ['No', 'Si'],
					required: false,
					errorMessage: []
				},
				question1: {
					value: "",
					type: "textarea",
					label: "¿Por qué estás interesado en trabajar con nosotros?",
					rows: 1,
					required: false,
					errorMessage: [],
					helpText: "Platícanos más sobre tu interés."
				}
			},
			resume: {
				file: '',
				error: false
			},
			sent: false,
			isSending: false
		}
	},
	computed: {
		contactData() {
			return Object.entries(this.contact).reduce((acc, el) => ({...acc, [el[0]]: el[1].value }), {})
		}
	},
	methods: {
		attachFile(file) {
			this.resume.file = file;
		},
		doSubmit(token) {

			let formData = new FormData();
			formData.append("file", this.resume.file);
			formData.append("candidate", JSON.stringify(this.contactData));
			formData.append("recaptchaToken", token);

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}

			this.isSending = true;
			axios.post('/api/apply', formData, config)
				.then(response => {
					console.log(response);
					this.sent = true;
				})
				.catch(error => console.log(error))
				.finally(() => {
					this.isSending = false;
					this.$emit('sent', this.contactData.name)
				});
		},
		submitForm() {
			if (this.checkForm(this.contact) && this.checkAttachment()) {
				this.$refs.recaptcha.execute();
			}
		},
		checkAttachment() {
			this.resume.error = !this.resume.file;
			return !this.resume.error;
		}
	}
}

</script>

<style scoped>
.apply-form-component {
	margin: 2rem auto 0;
	background-color: #ffffff;
	padding: 1rem 3rem;
	border-radius: 1rem;
}

.apply-form-component.mobile {
	padding: 1rem 1.5rem;
}

.apply-form-component.desktop {
	max-width: 80%;	
}

.desktop .subtitle {
	margin: 2rem auto;
	max-width: 80%;
}

.apply-form {
	font-family: 'open sans', helvetica, sans-serif;
}

.apply-form__submit {
	margin: 2.5rem auto 2rem;	
}
</style>