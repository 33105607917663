<template>
	<div class="file-upload">
		<p class="file-upload__description">
			 <slot></slot>
		</p>	
		<input class="file-upload__input" type="file" ref="file" @change="handleFileUpload()" :accept="options.accepted.join()" hidden/>
		<a class="lnk file-upload__link" @click.prevent="chooseFile">
			<slot name="action">selecciona un archivo</slot>
		</a>
		<div class="file-upload__specs">
			{{ specs }}
		</div>
		<div class="file-upload__state">
			<span class="file-upload__error" v-show="state.error">
				<fa-icon :icon="['fas', 'times']" />
			</span>
			{{ state.message }}
		</div>
	</div>
	<div class="form-field__help-text">
		<slot name="helptext"></slot>
	</div>
</template>

<script>
	import { validateFile } from "@/utils.js";
	export default {
		name: 'BaseInputFile',
		emits: ['file'],
		props: ['type', 'size'],
		data() {
			return {
				state: {
					message: '',
					error: false
				}
			}
		},
		computed: {
			options() {
				return {
					accepted: this.type?.split(',') || [],
					limit: this.size || 0
				}	
			},
			specs() {
				const types = this.options.accepted.map(type => type.split('/')[1].toUpperCase());
				return `${ types.join(', ') } (max. ${ this.options.limit } MB)`;
			}
		},
		methods: {
			chooseFile() {
				this.$refs.file.click();
			},
			handleFileUpload() {
				const file = this.$refs.file.files[0];
				validateFile(file, this.options)
					.then(() => {
						this.state.message = file.name;
						this.state.error = false;
						this.$emit('file', file);
					})
					.catch(err => {
						this.state.message = err;
						this.state.error = true;
						this.$emit('file', '');
					})
			}
		}
	}
</script>

<style scoped>
.file-upload {
	position: relative;
	width: 100%;
	border: 1px solid #c2c8cd;
	border-radius: .25rem;
	margin: 3rem auto 0;
	padding: 2rem;
	color: #657380;
}

.file-upload__description {
	font-size: 1.0625rem;
	margin: 0 0 1em;
}

.file-upload__input {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	top: 0;
	left: 0;
	cursor: pointer;
}

.file-upload__link {
	font-size: .875rem;
}

.file-upload__specs {
	color: #848688;
	font-size: .6875em;
	margin-top: .175em;
}

.file-upload__state {
	font-size: .8125rem;
	margin-top: 1rem;
}

.file-upload__error {
	color: var(--error-color);
	margin-right: .25em;
}
</style>